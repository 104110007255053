<template>
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">人脸认证预警</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div
              title="学员姓名"
              style="display: flex; align-items: center"
              class="searchboxItem"
            >
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div
              title="身份证号"
              style="display: flex; align-items: center"
              class="searchboxItem"
            >
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="课程名称" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
           
            <div title="处理状态" class="searchboxItem">
              <span class="itemLabel">处理状态:</span>
              <el-select
                v-model="handleStatus"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in userFaceWarningList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="培训地区" class="searchboxItem ci-full">
              <span class="itemLabel">培训地区:</span>
              <el-cascader
                :props="{ emitPath: false, checkStrictly: true }"
                v-model="areaId"
                :options="areatreeList"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
             <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel" >所属企业:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div style="padding-left: 10px">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                minWidth="120"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="160"
                fixed
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="所属企业"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="120"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="150"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                minWidth="150"
              />
              <el-table-column
                label="预警次数"
                align="left"
                show-overflow-tooltip
                prop="warningNum"
              />

              <el-table-column
                label="处理状态"
                align="center"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  $setDictionary(
                    "USER_FACE_WARNING_HANDLE_STATUS",
                    scope.row.handleStatus
                  )
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0 5px"
                    size="mini"
                    @click="lookStudyRecord(scope.row, scope.$index)"
                    >查看认证照片</el-button
                  >
                  <el-button
                    v-if="scope.row.handleStatus == '10'"
                    type="text"
                    style="padding: 0 5px"
                    size="mini"
                    @click="handleWarning(scope.row.warningId)"
                    >处理</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "operate/faceAuthenticationWarning",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "", //学员姓名
      idcard: "", //身份证号
      mobile: "", //手机号
      projectCode: "", //班级编码
      projectName: "", //班级名
      courseName: "", //课程名
      handleStatus: "", // 处理状态
      userFaceWarningList: [], //处理状态数据
      areaId: "", //行政区划
      areatreeList: [], // 行政区划
      pageNumIndex: "", // 记录当前第几页
      CompanyList:[], //所属企业
      compId: "", //机构Id
    };
  },
  created() {},
  mounted() {
    this.getareatree();
    this.getUserFaceWarningList();
  },
  methods: {
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取培训区划
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      this.pageNumIndex = pageNum;
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.handleStatus) {
        params.handleStatus = this.handleStatus;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/biz/userface/warning/pageList",
        params,
        pageNum,
      });
    },
    // 获取码值 - 处理状态
    getUserFaceWarningList() {
      const userFaceWarningList = this.$setDictionary(
        "USER_FACE_WARNING_HANDLE_STATUS",
        "list"
      );
      for (const key in userFaceWarningList) {
        this.userFaceWarningList.push({
          value: key,
          label: userFaceWarningList[key],
        });
      }
    },
    // 处理
    handleWarning(warningId) {
      this.$post("/biz/userface/warning/handle", { warningId }).then((res) => {
        if (res.status == "0") {
          this.$message.success(res.message);
          this.getData(-1);
        }
      });
    },
    // 查看认证照片
    lookStudyRecord(row, index) {
      const params = {
        pageNum: this.pageNumIndex == -1 ? 1 : this.pageNumIndex,
        pageSize: this.pageSize,
        userIndex: index,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.handleStatus) {
        params.handleStatus = this.handleStatus;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      this.$router.push({
        path: "/web/operate/faceAuthenticationWarningScreen",
        query: params,
      });
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 +5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.CertificationBox {
  display: flex;
  .certificationTitle {
    font-weight: bolder;
  }
  .certifictionImg {
    padding-left: 20px;
  }
  .el-image {
    width: 210px;
    height: 230px;
  }
}
.failTitle {
  font-weight: bolder;
  padding: 20px 0;
}
.shells {
  height: 200px;
  .shell {
    width: 117px;
    height: 100px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .el-checkbox {
      position: absolute;
      right: 0;
      top: -1px;
    }
  }
}
.pageNum {
  margin-top: 10px;
}
</style>
